import { createSelector } from '@ngrx/store';
import { AppState } from '@app/store';
import { adapter } from './session.reducer';
import {
  ParticipantStatus,
  ParticipantType,
  Participant,
} from './session.model';
import { selectIsFreemium } from '../../freemium-status/store';

import * as fromWindow from '@common/window/store/window.selectors';
import * as fromFirebase from '@common/firebase/store/firebase.selectors';
import { selectClientClickMuted, selectSharedCursorOn } from '../../app/store';

export const selectSession = (state: AppState) => state.session;

const { selectAll, selectEntities, selectIds } =
  adapter.getSelectors(selectSession);

export const selectSessionInitialized = createSelector(
  selectSession,
  session => !!session.localId,
);

export const selectIsObserverPendingInteraction = createSelector(
  selectSession,
  session => session.isObserverInteractionPending,
);

export const selectSessionIds = selectIds as (state: AppState) => string[];

export const selectParticipantsMap = selectEntities;

export const selectParticipants = selectAll;

const isOnline = (participant: Participant) => !participant.offline;
export const selectOnlineParticipants = createSelector(
  selectParticipants,
  participants => participants.filter(isOnline),
);

export const selectLocalParticipantId = createSelector(
  selectSession,
  state => state.localId,
);

export const selectLocalParticipant = createSelector(
  selectLocalParticipantId,
  selectEntities,
  (localId, participants) => participants[localId],
);

export const selectLocalParticipantType = createSelector(
  selectLocalParticipant,
  participant => participant?.type,
);

export const selectIsLocalParticipantHost = createSelector(
  selectLocalParticipantType,
  type => type === ParticipantType.host,
);

export const selectIsLocalParticipantObserver = createSelector(
  selectLocalParticipantType,
  type => type === ParticipantType.observer,
);

export const selectIsLocalParticipantGuest = createSelector(
  selectLocalParticipantType,
  type => type === ParticipantType.guest,
);

export const selectRemoteSessionIds = createSelector(
  selectSessionIds,
  selectLocalParticipantId,
  (sessionIds, localId) => sessionIds.filter(id => id !== localId),
);

export const selectRemoteParticipant = (participantId: string) =>
  createSelector(
    selectRemoteSessionIds,
    selectEntities,
    (remoteIds, entities) => {
      if (!remoteIds.includes(participantId)) {
        return null;
      }
      return entities[participantId];
    },
  );

const hasJoined = (participant: Participant) =>
  participant.status === ParticipantStatus.joined;
export const selectJoinedStudents = createSelector(
  selectParticipants,
  participants =>
    participants.filter(p => p.type === ParticipantType.guest && hasJoined(p)),
);

export const selectWaitingRoomList = createSelector(
  selectParticipants,
  participants =>
    participants.filter(
      p =>
        p.status === ParticipantStatus.waiting ||
        p.status === ParticipantStatus.entering ||
        p.status === ParticipantStatus.enteringTimeout,
    ),
);

export const selectParticipant = (participantId: string) =>
  createSelector(selectEntities, entities => entities[participantId]);

export const selectParticipantType = (participantId: string) =>
  createSelector(
    selectParticipant(participantId),
    participant => participant?.type,
  );

export const selectParticipantStatus = (participantId: string) =>
  createSelector(
    selectParticipant(participantId),
    participant => participant?.status,
  );

export const selectIsParticipantEntering = (participantId: string) =>
  createSelector(
    selectParticipantStatus(participantId),
    status => status === ParticipantStatus.entering,
  );

export const selectHasParticipantJoined = (participantId: string) =>
  createSelector(
    selectParticipantStatus(participantId),
    status => status === ParticipantStatus.joined,
  );

export const selectParticipantIsViewingPage = (participantId: string) =>
  createSelector(
    selectParticipant(participantId),
    participant => participant?.isViewingPage,
  );

export const selectParticipantIsHost = (participantId: string) =>
  createSelector(
    selectParticipantType(participantId),
    type => type === ParticipantType.host,
  );

export const selectParticipantIsGuest = (participantId: string) =>
  createSelector(
    selectParticipantType(participantId),
    type => type === ParticipantType.guest,
  );

export const selectParticipantIsLocal = (participantId: string) =>
  createSelector(
    selectLocalParticipantId,
    localId => localId === participantId,
  );

export const selectIsParticipantOffline = (participantId: string) =>
  createSelector(selectParticipant(participantId), participant =>
    participant ? participant.offline : true,
  );

export const selectPreventParticipantDismiss = (participantId: string) =>
  createSelector(
    fromFirebase.selectIsFirebaseConnected,
    selectIsParticipantEntering(participantId),
    (isConnected, isEntering) => !isConnected || isEntering,
  );

export const selectParticipantIsMouseMuted = (participantId: string) =>
  createSelector(
    selectParticipant(participantId),
    participant => participant?.isMouseClickMuted,
  );

export const selectLocalParticipantIsMouseMuted = createSelector(
  selectLocalParticipant,
  participant => participant?.isMouseClickMuted,
);

export const selectCombinedMouseCheckForLocalParticipant = createSelector(
  selectClientClickMuted,
  selectLocalParticipantIsMouseMuted,
  (clientClickMuted, localParticipantMouseMuted) =>
    clientClickMuted || localParticipantMouseMuted,
);

export const selectCombinedMouseCheckForParticipant = (participantId: string) =>
  createSelector(
    selectClientClickMuted,
    selectParticipantIsMouseMuted(participantId),
    (clientClickMuted, participantMouseMuted) =>
      clientClickMuted || participantMouseMuted,
  );

export const selectParticipantIsMouseCursorShared = (participantId: string) =>
  createSelector(
    selectParticipant(participantId),
    participant => participant?.isMouseCursorShared,
  );

export const selectLocalParticipantIsMouseCursorShared = createSelector(
  selectLocalParticipant,
  participant => participant?.isMouseCursorShared,
);

export const selectCombinedMouseCursorSharedCheckForLocalParticipant =
  createSelector(
    selectSharedCursorOn,
    selectLocalParticipantIsMouseCursorShared,
    (sharedCursorOn, localParticipantCursorShared) => {
      return sharedCursorOn || localParticipantCursorShared;
    },
  );

export const selectCombinedCursorCheckForParticipant = (
  participantId: string,
) =>
  createSelector(
    selectSharedCursorOn,
    selectParticipantIsMouseCursorShared(participantId),
    (sharedCursorOn, participantCursorShared) =>
      sharedCursorOn || participantCursorShared,
  );

export const selectAtLeastOneStudent = createSelector(
  selectJoinedStudents,
  participants => participants.length > 0,
);

export const selectAtLeastOneStudentAndFreemium = createSelector(
  selectAtLeastOneStudent,
  selectIsFreemium,
  (atLeastOneStudent, isFreemium) => atLeastOneStudent && isFreemium,
);

export const selectActiveParticipants = createSelector(
  selectOnlineParticipants,
  participants => participants.filter(hasJoined),
);

export const selectIsYoutubeInteractionPending = createSelector(
  selectLocalParticipant,
  participant => participant?.isYoutubeInteractionPending,
);

const isIPad = (p: Participant) => p.isIPad;
export const selectIPadGuests = createSelector(
  selectJoinedStudents,
  participants => participants.filter(isIPad),
);

const hasYoutubeInteractionPending = (p: Participant) =>
  p.isYoutubeInteractionPending;
export const selectIPadGuestsWithYoutubeInteractionPending = createSelector(
  selectIPadGuests,
  participants => participants.filter(hasYoutubeInteractionPending),
);

export const selectIsLocalParticipantIPad = createSelector(
  selectLocalParticipant,
  participant => participant && isIPad(participant),
);

export const selectIsLocalParticipantGuestIPad = createSelector(
  selectIsLocalParticipantGuest,
  selectIsLocalParticipantIPad,
  (isGuest, _isIPad) => isGuest && _isIPad,
);

const selectHostParticipant = createSelector(selectParticipants, participants =>
  participants.find(p => p.type === ParticipantType.host),
);

export const selectHostUserId = createSelector(
  selectHostParticipant,
  host => host && host.userId,
);

export const selectHostId = createSelector(
  selectHostParticipant,
  host => host && host.id,
);

export const selectIsHostAbsent = createSelector(
  selectHostParticipant,
  host => !host || (host && host.offline),
);

export const selectHostIsMouseMuted = createSelector(
  selectHostParticipant,
  participant => participant?.isMouseClickMuted,
);

export const selectHostIsMouseCursorShared = createSelector(
  selectHostParticipant,
  participant => participant?.isMouseCursorShared,
);

export const selectGuestIsNotViewingPage = (participantId: string) =>
  createSelector(
    selectIsLocalParticipantHost,
    selectParticipantIsGuest(participantId),
    selectParticipantIsViewingPage(participantId),
    (isLocalHost, isGuest, isViewingPage) =>
      isLocalHost && isGuest ? !isViewingPage : false,
  );

export const selectShowVideoStates = (participantId: string) =>
  createSelector(
    selectParticipantIsLocal(participantId),
    selectIsLocalParticipantHost,
    (isLocal, isLocalHost) => isLocal || isLocalHost,
  );

export const selectIsLocalDisconnected = createSelector(
  fromWindow.selectIsOnline,
  fromFirebase.selectIsFirebaseDisconnected,
  (isNetOnline, isFirebaseDisconnected) =>
    !isNetOnline || isFirebaseDisconnected,
);
